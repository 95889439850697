.footer-input::placeholder {
    @apply font-bold;
}

.modal-content {
    min-height: 150px;
}

.join-button {
    min-width: 150px;
}

.join-button:focus-visible {
    @apply ring-gray !important;
}

@media (min-width: theme(screens.md)) {
    .subscription-section {
        max-width: 390px;
    }
}
