.markdown {
    @apply text-lg leading-relaxed;
}

.markdown ul,
.markdown ol,
.markdown blockquote {
    @apply ml-12;
}

.markdown h2 {
    @apply text-3xl mt-12 mb-4 leading-snug;
}

.markdown h3 {
    @apply text-2xl mt-8 mb-4 leading-snug;
}
