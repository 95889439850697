/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

/* put base styles inside the base directive */
@layer base {
    html,
    #__next {
        @apply h-full;
    }

    /* apply pointer cursor to non-next links */
    a {
        @apply cursor-pointer;
    }

    p {
        letter-spacing: 0.01em;
        line-height: 1.4em;
    }
    /* apply custom focus outline to all focusable elements */
    *:focus:not(.allow-focus-ring) {
        @apply outline-none ring ring-gray-darker/50 !important;
    }

    /**
    * undo focus styles for elements focused by mouse click
    * this is ignored by browsers that don't support :focus-visible
    * which means that the above focus styles will continue to apply
    * for those browsers
    */
    *:focus:not(:focus-visible):not(.allow-focus-ring) {
        @apply outline-none ring-0 !important;
    }

    /* add focus styles back to elements that are focused by keyboard navigation */
    *:focus-visible:not(.allow-focus-ring) {
        @apply outline-none ring ring-gray-darker/50 !important;
    }

    @font-face {
        font-family: Messina;
        font-display: swap;
        font-weight: 500;
        src: url('../public/fonts/MessinaSansWeb-Regular.woff2') format('woff2'),
            url('../public/fonts/MessinaSansWeb-Regular.woff2') format('woff');
    }

    @font-face {
        font-family: Messina;
        font-weight: 700;
        font-display: swap;
        src: url('../public/fonts/MessinaSansWeb-SemiBold.woff2') format('woff2'),
            url('../public/fonts/MessinaSansWeb-SemiBold.woff') format('woff');
    }

    html {
        font-family: Messina, sans-serif;
        @apply text-black;
    }

    body {
        @apply bg-gray-lighter;
        transition-property: margin;
        @apply duration-200;
    }

    b,
    strong {
        font-weight: 700;
    }

    @media screen and (prefers-reduced-motion: no-preference) {
        html {
            scroll-behavior: smooth;
        }
    }

    /* todo: new style guide for type system */
    h2 {
        @apply text-2.75xl v-md:text-3.5xl lg:text-3.5xl leading-110% v-md:leading-110% lg:leading-110% tracking-[-0.84px] v-md:tracking-[-1.36px] lg:tracking-[-1.36px];
    }

    /* override NextErrorComponent's style  */
    /* this is bad from Next, it should have applied this
    to just the component and not the body */
    @media (prefers-color-scheme: dark) {
        body {
            @apply !text-black;
        }
    }
}

@layer components {
    .button {
        @apply px-5 pt-3.5 pb-4 text-base lg:text-xbase leading-110% -tracking-11 rounded-full text-center border disabled:opacity-60 font-bold;
    }

    .button-primary {
        @apply border-black bg-black text-white;
    }

    .button-secondary {
        @apply border-black bg-transparent text-black transition-colors duration-200;
    }

    /* add no-hover class to any secondary button that does not
     * want hover effect
    */
    .button-secondary:not(.no-hover) {
        @apply can-hover:hover:text-white can-hover:hover:bg-black transition-colors duration-200;
    }

    .button-icon {
        @apply p-2 border-0;
    }

    .button-plain {
        @apply text-black-lighter border-0 underline;
    }

    .button-sm {
        @apply py-1;
    }

    .button-skinny {
        @apply px-3.5 py-2.5;
    }

    .button-bloated {
        @apply pt-[19px] pb-[21px];
    }

    .button-bloated--responsive {
        @apply pt-3.5 pb-4 v-md:pt-[19px] v-md:pb-[21px] lg:pt-[19px] lg:pb-[21px];
    }

    .tag {
        @apply border border-black rounded-md px-2 py-1 text-sm inline-block;
    }

    .card {
        @apply p-2 rounded-lg border border-transparent can-hover:hover:border-gray transition-all duration-200;
    }

    .alert {
        @apply bg-error-medium/30 p-5 h-full text-xsm lg:text-sm rounded-2lg overflow-hidden;
    }

    .pill {
        @apply px-3.5 py-1.5 text-sm font-semibold border border-gray-lighter rounded-4xl tracking-wide;
    }

    .placeholder-skeleton {
        position: relative;
        overflow: hidden;
    }

    .placeholder-skeleton::before {
        content: '';
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 80px;
        background: linear-gradient(to right, transparent 0%, #989898 50%, transparent 100%);
        @apply animate-shift;
    }

    .placeholder-skeleton-light::before {
        background: linear-gradient(to right, transparent 0%, #eeecec 50%, transparent 100%);
    }

    .cabinet-container {
        /* specify 2xl max screen size specifically to fix overwrite by lg:max-w-none */
        @apply md:container md:mx-auto lg:max-w-none 2xl:container 2xl:max-w-screen-2xl 2xl:mx-auto;
    }

    .token-shape-crop {
        clip-path: url('#token-clip-path');
    }

    .section {
        @apply px-5 py-10 sm:px-6 lg:p-15;
    }

    .section--v2 {
        @apply px-5 sm:px-6 lg:px-15 my-[var(--sectionBlockMargin)];
    }

    .section--v2--sm {
        @apply px-5 sm:px-6 lg:px-15 my-8 sm:my-[var(--sectionBlockMargin)];
    }

    .section--small-margin-top {
        @apply -mt-[var(--sectionBlockMarginNegativeAdjustment)] py-[var(--sectionBlockMargin)];
    }

    .section--small-margin-block {
        @apply -my-[var(--sectionBlockMarginNegativeAdjustment)] py-[var(--sectionBlockMargin)];
    }

    .section--clear-margin-top {
        @apply -mt-[var(--sectionBlockMargin)];
    }

    .section--header-small {
        @apply text-2xl lg:text-4xl tracking-tight leading-110%;
    }

    .section--header {
        @apply text-3.5xl lg:text-6.25xl tracking-tight leading-110%;
    }

    .section--header-big {
        @apply text-3xl lg:text-6xl leading-110%;
    }

    .section--header-max-width {
        @apply max-w-4xl;
    }

    .section--subheader {
        @apply lg:text-xl;
    }

    .section--subheader-big {
        @apply text-xbase lg:text-base leading-140%;
    }

    .section--paragraph {
        @apply lg:text-lg;
    }

    /* Typography classes moved from our-approach module */
    .cb-hero {
        @apply text-5xl md:text-8xl;
    }

    .cb-h1 {
        @apply text-cb-5xl md:text-cb-7xl;
    }

    .cb-h1-small {
        @apply text-2xl md:text-4xl pb-4;
    }

    .cb-h2 {
        @apply text-cb-3.5xl md:text-cb-6.5xl;
    }

    .cb-h2-small {
        @apply text-xl md:text-3xl pb-3;
    }

    .cb-h3 {
        @apply text-cb-2.75xl md:text-cb-6xl;
    }

    .cb-h3-small {
        @apply text-lg md:text-2xl pb-2;
    }

    .cb-h4 {
        @apply text-cb-1xl md:text-cb-3.5xl;
    }

    .cb-h5 {
        @apply text-cb-1xl md:text-cb-3.25xl;
    }

    .cb-p-large {
        @apply text-lg lg:text-base leading-140% tracking-[-0.54px] lg:-tracking-12;
    }

    .cb-p {
        @apply text-base leading-140% -tracking-12;
    }

    .cb-p-small {
        @apply text-xs v-md:text-sm lg:!text-base xl:!text-lg leading-[128%] tracking-[-0.6px];
    }

    .section--primary-cta {
        min-width: 268px;
    }

    .section--container {
        @apply max-w-5xl;
    }

    .section--bigger-padding {
        @apply py-17.5 lg:py-[110px];
    }

    .hero--header {
        @apply text-3.5xl lg:text-6.25xl;
    }
}

@layer utilities {
    .flex-basis-full {
        flex-basis: 100%;
    }

    .flex-basis-half {
        flex-basis: 50%;
    }

    .flex-basis-33 {
        flex-basis: 33.33%;
    }

    .flex-basis-25 {
        flex-basis: 25%;
    }

    .flex-basis-0 {
        flex-basis: 0;
    }

    .scroll-margin-top-3 {
        scroll-margin-top: 3rem;
    }

    .scroll-margin-top-6 {
        scroll-margin-top: 6rem;
    }

    .scroll-margin-top-7 {
        scroll-margin-top: 7rem;
    }

    .scroll-margin-top-11 {
        scroll-margin-top: 11rem;
    }

    .scroll-margin-top-19 {
        scroll-margin-top: 19rem;
    }

    /* defines scroll margin top as the size of header */
    .scroll-margin-header {
        scroll-margin-top: var(--pageHeaderHeight);
    }

    .text-shadow {
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3), -2px 2px 6px rgba(0, 0, 0, 0.15);
    }

    /* useful in places where font-bold transition
    causes slight layout shift */
    .text-shadow-bold {
        text-shadow: 0.1px 0.1px currentColor;
    }

    .max-h-infinity {
        /* this should be enough to cover all cases */
        max-height: 9999px;
    }

    .text-revert {
        color: revert;
    }

    .text-inherit {
        color: inherit;
    }

    /* show shadow when there's an overflowing container */
    .scroll-shadow {
        background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
            linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0)),
            linear-gradient(to left, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0));
        background-position: left center, right center, left center, right center;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 10px 100%, 10px 100%, 10px 100%, 10px 100%;
        /* attachment local does not work in safari*/
        background-attachment: local, local, scroll, scroll;
    }

    .scrollbar-hidden {
        scrollbar-width: none;
    }

    .scrollbar-hidden::-webkit-scrollbar {
        @apply h-0 w-0;
        scrollbar-width: none;
    }

    .blockquote {
        quotes: auto;
    }

    .blockquote::before {
        content: open-quote;
    }

    .blockquote::after {
        content: close-quote;
    }

    /* needs parent to be a flex container */
    .bleed-scroll--make_element_flex {
        @apply -mx-5 sm:-mx-6 lg:-mx-15 before:flex-shrink-0 after:flex-shrink-0 before:w-5 sm:before:w-6 lg:before:w-15 after:w-5 sm:after:w-6 lg:after:w-15;
    }

    .z-overlay {
        @apply z-30;
    }

    .z-header {
        @apply z-50;
    }

    .z-banner {
        @apply z-50;
    }

    .z-modal {
        @apply z-50;
    }

    .z-toast {
        @apply z-[60];
    }
}

:root {
    --pageHeaderHeight: 60px;
    --modalXPadding: 12px;
    --modalTopPadding: 28px;
    --modalTopPaddingLg: 48px;
    --medicineModalHeaderHeight: 60px;
    --scrollBarGutter: 0px;
    --fixedToBottomElementHeight: 0px;
    --bannerHeight: 0px;
    --iconsBannerHeight: 0px;
    --headerBackgroundColorTransparentState: transparent;
    --headerPlusBannerHeight: calc(var(--pageHeaderHeight) + var(--bannerHeight));
    --screenHeightDeductingHeader: calc(100vh - var(--headerPlusBannerHeight));
    --sectionBlockMargin: 45px;
    --heroMaxHeight: 850px;
    --sectionBlockMarginNegativeAdjustment: 55px;
    --secondaryNavbarHeight: 0px;
}

body {
    padding-bottom: var(--fixedToBottomElementHeight);
}

body.no-scroll {
    @apply overflow-hidden;
    padding-right: var(--scrollBarGutter);
}

:disabled {
    cursor: not-allowed;
}

@media print {
    html,
    body {
        @apply h-auto;
    }

    .print-mode__invisible * {
        @apply !invisible;
    }

    .print-mode__visible * {
        @apply !visible;
    }
}
