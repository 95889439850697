/* Small loading animation */
.lds-ellipsis {
    width: 40px;
    height: 20px;
    @apply flex relative items-center;
}
.lds-ellipsis :global div {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    @apply bg-current absolute;
}

.lds-ellipsis :global div:nth-child(1) :local {
    left: 4px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis :global div:nth-child(2) :local {
    left: 4px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis :global div:nth-child(3) :local {
    left: 16px;
    animation: lds-ellipsis2 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(12px, 0);
    }
}
