.modal-wrapper {
    backgroound: rgba(196, 196, 196, 0.2);
}

.modal {
    max-width: 450px;
    margin-top: 50px;
}

@media (min-width: theme(screens.lg)) {
    .modal {
        margin-block: auto;
    }
}
