.signup-popup-overlay {
    background: rgba(196, 196, 196, 0.6);
}

.signup-popup {
    box-shadow: 0px 0px 38px 12px rgba(0, 0, 0, 0.14);
    background: rgba(196, 196, 196, 0.6);
}

.button {
    width: 80px;
}

/* flip styles */
.flip-card {
    @apply w-full bg-transparent;
    height: 50px;
    perspective: 1000px; /* 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    @apply relative w-full h-full transition-transform duration-700;
    transform-style: preserve-3d;
}

/* Do an vertical flip */
.flip-card:global(.flip) .flip-card-inner {
    transform: rotateX(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
    @apply absolute w-full h-full;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0); /* needed for safari 12.1 */
}

.flip-card-back {
    transform: rotateX(180deg);
    /* transparent outline fixes ios safari black background bug */
    @apply outline-none;
}

.modal-close-button-big {
    @apply lg:top-6 lg:right-4 lg:h-10 lg:w-10 lg:translate-x-0 lg:translate-y-0 lg:flex lg:justify-center lg:items-center;
}

.modal-close-button-big :global(svg) {
    @apply h-3 w-3;
}

@media (max-width: theme(screens.md)) {
    .signup-popup-overlay {
        @apply p-0 items-end;
    }

    .signup-popup {
        @apply max-w-none;
    }

    .modal-close-button {
        @apply h-11 w-11 translate-x-0 translate-y-0 top-2.5 right-1.5 flex justify-center items-center;
    }

    .modal-close-button :global(svg) {
        @apply h-3 w-3;
    }

    .modal-close-button :global(path) {
        @apply text-white stroke-current;
    }
}
