.modal-wrapper {
    padding-left: var(--modalXPadding);
    padding-right: calc(var(--modalXPadding));
}

.modal-wrapper:global(.full-page) {
    padding-top: var(--modalTopPadding);
}

@media (min-width: theme(screens.lg)) {
    .modal-wrapper:global(.full-page) {
        padding-top: var(--modalTopPaddingLg);
    }
}

@media (min-width: theme(screens.2xl)) {
    .modal {
        max-width: calc(theme(screens.2xl) - (var(--modalXPadding) * 2) - var(--scrollBarGutter));
    }
}
