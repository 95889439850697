/* hide default browser appearance */
.custom-checkbox {
    /* w-0 needed for iOS safari
     * wihout w-0, width of input 
     * disturbs layout
    */
    @apply appearance-none w-0;

    /* hide focus ring, needed for safari */
    @apply relative -z-10;
}

.checkmark {
    @apply border border-black rounded min-h-4 min-w-4 mt-0.5 flex-shrink-0 flex justify-center items-center mr-2.5;
}

.checkmark > svg {
    @apply invisible;
}

.custom-checkbox:checked ~ .checkmark {
    background-color: var(--checked-bg-color);
}

.custom-checkbox:checked ~ .checkmark > svg {
    @apply visible;
}

.custom-checkbox:disabled ~ .checkmark {
    @apply opacity-60;
}

.custom-checkbox:focus {
    @apply outline-none;
}

.custom-checkbox:focus-visible {
    @apply ring-0 !important;
}

/* add focus ring */
.custom-checkbox:focus ~ .checkmark {
    @apply ring ring-gray-darker/50;
}

/* remove focus ring when focus is caused by mouse-click */
.custom-checkbox:focus:not(:focus-visible) ~ .checkmark {
    @apply outline-none ring-0 !important;
}

/* add focus ring when focus is caused by keyboard */
.custom-checkbox::focus-visible ~ .checkmark {
    @apply outline-none ring ring-gray-darker/50 !important;
}
