.toast {
    animation: fade 0.25s ease-out forwards;
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
